import { DrawerHeaderItem as HeaderItemsDTO } from "./drawer-header-item";

export class DrawerHeaderGroup {
  label: string;
  active: boolean;
  activeItems: number;
  description: string;
  items: HeaderItemsDTO[];
}
//
// Campo descrição com mais de 40 caracteres vai quebrar a linha e a interface do componente.
//
export let SuperInvoicingDrawer: DrawerHeaderGroup[] = [
    {
      label: 'Faturas',
      active: false,
      activeItems: 2,
      description: 'Faturas emitidas pelo sistema',
      items: [
        {
          label: 'Número',
          name: 'invoiceNumber',
          toggle: true,
          required: true,
          parent: 'Faturas',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Gerado por',
          name: 'invoiceGeneratedBy',
          toggle: false,
          required: true,
          parent: 'Faturas',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Valor',
          name: 'invoiceValue',
          toggle: true,
          required: true,
          parent: 'Faturas',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Dt Emis',
          name: 'invoiceEmissionDate',
          toggle: false,
          required: false,
          parent: 'Faturas',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Dt Comp',
          name: 'invoiceCompetenceDate',
          toggle: true,
          required: true,
          parent: 'Faturas',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Dt Venc',
          name: 'invoiceDueDate',
          toggle: true,
          required: true,
          parent: 'Faturas',
          type: 'SUPER_INVOICING',
        },
      ]
    },
    {
      label: 'Pagamentos',
      active: false,
      activeItems: 2,
      description: 'Pagamentos efetuados no sistema',
      items: [
        {
          label: 'Valor',
          name: 'paymentValue',
          toggle: true,
          required: true,
          parent: 'Pagamentos',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Dt Pag',
          name: 'paymentDate',
          toggle: true,
          required: true,
          parent: 'Pagamentos',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Resp Pag',
          name: 'paymentResponsibleEmployee',
          toggle: false,
          required: false,
          parent: 'Pagamentos',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Desc Pag',
          name: 'paymentDiscount',
          toggle: true,
          required: true,
          parent: 'Pagamentos',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Desc até Venc',
          name: 'paymentDueDateDiscount',
          toggle: true,
          required: true,
          parent: 'Pagamentos',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Juros/Multa',
          name: 'paymentInterestAndFine',
          toggle: true,
          required: true,
          parent: 'Pagamentos',
          type: 'SUPER_INVOICING',
        },
      ]
    },
    {
      label: 'Cliente',
      active: false,
      activeItems: 1,
      description: 'Clientes cadastrados no sistema',
      items: [
        {
          label: 'Nome',
          name: 'customerName',
          toggle: true,
          required: true,
          parent: 'Cliente',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Grupo',
          name: 'customerGroup',
          toggle: false,
          required: false,
          parent: 'Cliente',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'CPF/CNPJ',
          name: 'customerFederalId',
          toggle: true,
          required: true,
          parent: 'Cliente',
          type: 'SUPER_INVOICING',
        },
      ]
    },
    {
      label: 'NF-Serviço',
      active: false,
      activeItems: 3,
      description: 'NFE-Seviço emitidas pelo sistema',
      items: [
        {
          label: 'Número',
          name: 'nfeServiceNumber',
          toggle: true,
          required: true,
          parent: 'NF-Serviço',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Gerado por',
          name: 'nfeServiceGeneratedBy',
          toggle: false,
          required: true,
          parent: 'NF-Serviço',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Valor',
          name: 'nfeServiceValue',
          toggle: true,
          required: true,
          parent: 'NF-Serviço',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Dt Emis',
          name: 'nfeServiceEmissionDate',
          toggle: true,
          required: true,
          parent: 'NF-Serviço',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Dt Comp',
          name: 'nfeServiceCompetenceDate',
          toggle: true,
          required: true,
          parent: 'NF-Serviço',
          type: 'SUPER_INVOICING',
        }
      ]
    },
    {
      label: 'NF-Produto',
      active: false,
      activeItems: 1,
      description: 'NFE-Produto emitidas pelo sistema',
      items: [
        {
          label: 'Número',
          name: 'nfeProductNumber',
          toggle: true,
          required: true,
          parent: 'NF-Produto',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Gerado por',
          name: 'nfeProductGeneratedBy',
          toggle: false,
          required: true,
          parent: 'NF-Produto',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Valor',
          name: 'nfeProductValue',
          toggle: false,
          required: true,
          parent: 'NF-Produto',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Dt Emis',
          name: 'nfeProductEmissionDate',
          toggle: false,
          required: true,
          parent: 'NF-Produto',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Dt Comp',
          name: 'nfeProductCompetenceDate',
          toggle: false,
          required: true,
          parent: 'NF-Produto',
          type: 'SUPER_INVOICING',
        }
      ]
    },
    {
      label: '30%',
      active: false,
      activeItems: 2,
      description: 'Periódicos com emissão de nota',
      items: [
        {
          label: 'Qt itens',
          name: 'taxablePeriodicQuantity',
          toggle: true,
          required: true,
          parent: '30%',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Valor',
          name: 'taxablePeriodicValue',
          toggle: true,
          required: true,
          parent: '30%',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Desc até Venc',
          name: 'taxablePeriodicDueDateDiscount',
          toggle: true,
          required: true,
          parent: '30%',
          type: 'SUPER_INVOICING',
        },
        {
          label: '%Desc até Venc',
          name: 'taxablePeriodicDueDateDiscountPercentage',
          toggle: false,
          required: false,
          parent: '30%',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Descrição',
          name: 'taxablePeriodicDescription',
          toggle: false,
          required: false,
          parent: '30%',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Plano Conta',
          name: 'taxablePeriodicPlanoConta',
          toggle: false,
          required: false,
          parent: '30%',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Validação',
          name: 'taxablePeriodicValidation',
          toggle: true,
          required: true,
          parent: '30%',
          type: 'SUPER_INVOICING',
        }
      ]
    },
    {
      label: '70%',
      active: false,
      activeItems: 2,
      description: 'Periódicos com emissão de recibo',
      items: [
        {
          label: 'Qt itens',
          name: 'receiptPeriodicQuantity',
          toggle: true,
          required: true,
          parent: '70%',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Valor',
          name: 'receiptPeriodicValue',
          toggle: true,
          required: true,
          parent: '70%',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Desc até Venc',
          name: 'receiptPeriodicDueDateDiscount',
          toggle: true,
          required: true,
          parent: '70%',
          type: 'SUPER_INVOICING',
        },
        {
          label: '%Desc até Venc',
          name: 'receiptPeriodicDueDateDiscountPercentage',
          toggle: false,
          required: false,
          parent: '70%',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Descrição',
          name: 'receiptPeriodicDescription',
          toggle: true,
          required: true,
          parent: '70%',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Plano Conta',
          name: 'receiptPeriodicPlanoConta',
          toggle: false,
          required: false,
          parent: '70%',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Validação',
          name: 'receiptPeriodicValidation',
          toggle: true,
          required: true,
          parent: '70%',
          type: 'SUPER_INVOICING',
        }
      ]
    },
    {
      label: '100%',
      active: false,
      activeItems: 1,
      description: 'Total Periódico',
      items: [
        {
          label: 'Valor',
          name: 'periodicTotal',
          toggle: true,
          required: true,
          parent: '100%',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Desc até Venc',
          name: 'periodicTotalDueDateDiscount',
          toggle: true,
          required: false,
          parent: '100%',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Descrição',
          name: 'periodicDescription',
          toggle: true,
          required: false,
          parent: '100%',
          type: 'SUPER_INVOICING',
        }
      ]
    },
    {
      label: 'Avulso (NF)',
      active: false,
      activeItems: 2,
      description: 'Produto/Serviço com emissão de nota',
      items: [
        {
          label: 'Qt itens',
          name: 'taxableSporadicQuantity',
          toggle: true,
          required: true,
          parent: 'Avulso (NF)',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Valor',
          name: 'taxableSporadicValue',
          toggle: true,
          required: true,
          parent: 'Avulso (NF)',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Descrição',
          name: 'taxableSporadicDescription',
          toggle: true,
          required: true,
          parent: 'Avulso (NF)',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Plano Conta',
          name: 'taxableSporadicPlanoConta',
          toggle: false,
          required: false,
          parent: 'Avulso (NF)',
          type: 'SUPER_INVOICING',
        }
      ]
    },
    {
      label: 'Avulso (Recibo)',
      active: false,
      activeItems: 2,
      description: 'Produto/Serviço com emissão de recibo',
      items: [
        {
          label: 'Qt itens',
          name: 'receiptSporadicQuantity',
          toggle: true,
          required: true,
          parent: 'Avulso (Recibo)',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Valor',
          name: 'receiptSporadicValue',
          toggle: true,
          required: true,
          parent: 'Avulso (Recibo)',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Descrição',
          name: 'receiptSporadicDescription',
          toggle: true,  
          required: true,
          parent: 'Avulso (Recibo)',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Plano Conta',
          name: 'receiptSporadicPlanoConta',
          toggle: false,  
          required: false,
          parent: 'Avulso (Recibo)',
          type: 'SUPER_INVOICING',
        }
      ]
    },
    {
      label: 'PC - NF',
      active: false,
      activeItems: 2,
      description: 'Periódicos Cliente com emissão de nota',
      items: [
        {
          label: 'Qt itens',
          name: 'taxablePeriodicClientQuantity',
          toggle: true,
          required: true,
          parent: 'PC - NF',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Valor',
          name: 'taxablePeriodicClientValue',
          toggle: true,
          required: true,
          parent: 'PC - NF',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Desc até Venc',
          name: 'taxablePeriodicClientDueDateDiscount',
          toggle: false,
          required: false,
          parent: 'PC - NF',
          type: 'SUPER_INVOICING',
        },
        {
          label: '%Desc até Venc',
          name: 'taxablePeriodicClientDueDateDiscountPercentage',
          toggle: false,
          required: false,
          parent: 'PC - NF',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Descrição',
          name: 'taxablePeriodicClientDescription',
          toggle: false,
          required: false,
          parent: 'PC - NF',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Plano Conta',
          name: 'taxablePeriodicClientPlanoConta',
          toggle: false,
          required: false,
          parent: 'PC - NF',
          type: 'SUPER_INVOICING',
        }
      ]
    },
    {
      label: 'PC - RECIBO',
      active: false,
      activeItems: 2,
      description: 'Periódicos Cliente com emissão de recibo',
      items: [
        {
          label: 'Qt itens',
          name: 'receiptPeriodicClientQuantity',
          toggle: true,
          required: true,
          parent: 'PC - RECIBO',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Valor',
          name: 'receiptPeriodicClientValue',
          toggle: true,
          required: true,
          parent: 'PC - RECIBO',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Desc até Venc',
          name: 'receiptPeriodicClientDueDateDiscount',
          toggle: true,
          required: true,
          parent: 'PC - RECIBO',
          type: 'SUPER_INVOICING',
        },
        {
          label: '%Desc até Venc',
          name: 'receiptPeriodicClientDueDateDiscountPercentage',
          toggle: false,
          required: false,
          parent: 'PC - RECIBO',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Descrição',
          name: 'receiptPeriodicClientDescription',
          toggle: true,
          required: true,
          parent: 'PC - RECIBO',
          type: 'SUPER_INVOICING',
        },
        {
          label: 'Plano Conta',
          name: 'receiptPeriodicClientPlanoConta',
          toggle: false,
          required: false,
          parent: 'PC - RECIBO',
          type: 'SUPER_INVOICING',
        }
      ]
    },  
  ];